
ion-select::part(container) {
  margin: auto;
}

ion-select::part(label) {
  display: none;
}


ion-select::part(text) {
  font: icon;
  font-size: 21px;
}

.select-disabled {
  color: black !important;
}


ion-button {
  width: 100%;
}

#home-page {
  text-align: center;
}

.tnxTitle {
  font-size: 20px;
  font-family: "PloniBold";
  text-align: center;
  margin-top: 10px;
  margin-bottom: 15px;
}

  
.next-btn {
  --background: linear-gradient(255.76deg, #6CDFCA -35.38%, #9CEDDA 95.61%) !important;
  border-radius: 50px;
  box-shadow: 0px 6px 18px 0px rgba(65, 133, 120, 0.20);
  color: black;
  font-weight: bold;
  font-size: 20px;
  height: 60px;
  --border-radius: 50px;
}



.logo {
  margin-top: 25px;
  width: 170px;
  margin-left: 5%;
}

@media only screen and (min-width: 1000px)  {
  .logo {
    margin-left: 0% !important;
  }
}

.MuiPaper-root.MuiPaper-elevation.MuiPaper-elevation0.MuiMobileStepper-root.MuiMobileStepper-positionStatic.stepper {
  background-color: white !important;
}

.MuiMobileStepper-dot.MuiMobileStepper-dotActive {
  background-color: #6123D0 !important;
}

.thank_all {
  margin-top: -50px;
}
.paymentTable {
  margin: 0 auto;
  font-size: 22px;
  border: 1px #a2a2a2 solid;
  width: 70%;
  max-width: 300px;
  min-height: 75px;
  border-spacing: 0;
  border-collapse: separate;
  border-radius: 10px;
}

.paymentTable td:not(:first-child) {
  padding-right: 20px;
  border-right: 1px #a2a2a2 solid;

}
.paymentTable th {
  padding-top: 10px;
}
.paymentTable th:not(:first-child) {
  padding-right: 10px;
  border-right: 1px #a2a2a2 solid;
}

.paymentTable th:first-child, td:first-child {
  padding-left: 10px;
}

.sticky{
  position: fixed;
  bottom: 5px;  
  width: 100%;
  background-color: white;
}

/*hr {
  border: 0;
  clear:both;
  display:block;
  width: 75%;               
  background-color:#a2a2a2;
  height: 1px;
  margin-top: 15px;
}*/
.members-list {
  max-height: 150px;
  overflow-y: auto;

  text-align: right;
  margin: 0 auto;
  
  max-width: 50vw;
}

.members-list p {
  margin: 4px !important;
}


.alert-tappable.sc-ion-alert-ios, .alert-radio-label.sc-ion-alert-ios {
  background: #FFF !important;
}

.avatar-elem {
  position: absolute;
  top: 7px;
  left: 7px;
  background-color: #9CEDDA;
  height: 45px;
  width: 45px;
}

.user-avatar {
  position: relative;
  top: 10px;
  font-size: 20px; 
  font-weight: bold;
}